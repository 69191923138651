import React, { useEffect, useState } from "react";
import * as d3 from "d3";

function TreePlot() {
    const treeMargin = { top: 20, right: 20, bottom: 20, left: 20 };
    const duration = 750;
    const nodeWidth = 110;
    const nodeHeight = 36;
    const scale = 1;
    const treeDepth = 5;
    let treeWidth = window.innerWidth;
    let treeHeight = window.innerHeight;
    const data = [
        {
            name: "Root",
            parent: "null",
            children: [
                {
                    name: "Level 2: A",
                    parent: "Top Level",
                    children: [
                        {
                            name: "A1",
                            parent: "Level 2: A"
                        },
                        {
                            name: "A3",
                            parent: "Level 2: A"
                        },
                        {
                            name: "A4",
                            parent: "Level 2: A"
                        },
                        {
                            name: "A5",
                            parent: "Level 2: A"
                        },


                        {
                            name: "A2",
                            parent: "Level 2: A"
                        },

                    ]
                },
                {
                    name: "Level 2: B",
                    parent: "Top Level"
                },
                {
                    name: "Level 3: C",
                    parent: "Top Level"
                }
            ]
        }
    ];

    const [additionalLinks, setAdditionalLinks] = useState<any>([]);

    function initTree(treeData) {
        const tree = d3.tree().nodeSize([nodeHeight * 4, nodeWidth]);
        let root = d3.hierarchy(treeData[0]);
        root.x0 = treeHeight / 2;
        root.y0 = 0;

        const svg = d3.select("div#tree_view").append("svg")
            .attr("width", treeWidth)
            .attr("height", treeHeight)
            .call(d3.zoom().on("zoom", function (e) {
                svgGroup.attr("transform", e.transform);
            }))
            .append("g");

        const svgGroup = svg.append("g")
            .attr("transform", `translate(${treeWidth / 2},${treeMargin.top})`);

        // Create the additional link from A2 to Level 2: B
        let arr: any = []
        const pairNodeA2 = root.descendants().find(d => d.data.name === "A2");
        const pairNode2B = root.descendants().find(d => d.data.name === "Level 2: B");
        arr.push({
            source: pairNodeA2,
            target: pairNode2B
        });
        setAdditionalLinks([...arr])
        console.log('pairNodeA2', pairNodeA2, pairNode2B);


        updateTree(root, svgGroup, tree);

        d3.select(self.frameElement).style("height", "500px");
    }

    function updateTree(source, svgGroup, tree) {
        let nodes = tree(source).descendants();
        let links = source.links();

        // Nodes
        const node = svgGroup.selectAll("g.node")
            .data(nodes, function (d) {
                return d.id || (d.id = ++d.depth);
            });

        const nodeEnter = node.enter().append("g")
            .attr("class", "node")
            .attr("transform", d => `translate(${source.y0},${source.x0})`)
            .on("click", function (d) {
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }
                updateTree(d, svgGroup, tree);
            });

        nodeEnter.append("rect")
            .attr("width", nodeWidth)
            .attr("height", nodeHeight)
            .attr("y", -nodeHeight / 2)
            .style("fill", d => d._children ? "#ace3b5" : "#f4f4f9");

        nodeEnter.append("text")
            .attr("dy", ".35em")
            .attr("y", 0)
            .attr("x", nodeWidth / 2)
            .attr("text-anchor", "middle")
            .text(d => d.data.name);

        const nodeUpdate = nodeEnter.merge(node);

        nodeUpdate.transition()
            .attr("transform", d => `translate(${d.y},${d.x})`);

        console.log('links', links);

        // Links
        const link = svgGroup.selectAll("path.link")
            .data(links, function (d) {
                return d.target.id;
            });

        link.enter().insert("path", "g")
            .attr("class", "link")
            .attr("d", d3.linkHorizontal()
                .x(d => {
                    console.log('dddd1', d);
                    ; return d.y
                })
                .y(d => d.x));

        // Additional links


        let arr: any = []
        const pairNodeA2 = nodes.find(d => d.data.name === "A2");
        const pairNode2B = nodes.find(d => d.data.name === "Level 2: B");
        arr.push({
            source: pairNodeA2,
            target: pairNode2B,
            _source: pairNodeA2,
            _target: pairNode2B,
        });

        console.log('additionalLinks', additionalLinks, links);


        const additionalLink = svgGroup.selectAll("path.additionalLink")
            .data(arr);

        console.log('additionalLink', additionalLink);

        additionalLink.enter().insert("path", "g")
            .attr("class", "additionalLink")
            .attr("d", d3.linkHorizontal()
                .source(d => { console.log('dddd2', d); return [d.source.y, d.source.x] })
                .target(d => [d.target.y, d.target.x]))
            .attr("stroke", "#ccc")
            .attr("fill", "none");
    }

    console.log('additionalLinks', additionalLinks);


    useEffect(() => {
        initTree(data);

    }, []);

    return <div id="tree_view"></div>;
}

export default TreePlot;
