import styled from "styled-components";
import tw from "twin.macro";

export const StyledHeader  = styled.div.attrs({
    className: "flex justify-between items-center py-4 bg-white md:px-24 px-8 drop-shadow-md",
})`
& {
  img {
    ${tw`w-36`}
  }
  button {
    ${tw`bg-gray-100 uppercase text-xs h-1/2 text-white py-2 px-4 border text-black	 rounded`}
  }
  i {
      ${tw`pr-4`}
  }
}
`

export const LoginSignUp = styled.button`
  &:hover{
    border: 1px solid #0002fb;
  }
`

export const LoginDiv = styled.div.attrs({
  className:'md:right-24 right-8'
})`
  border: 2px solid #0002fb;
  position:absolute;
  top:60px;
  border-radius:10px;
  background:#fff;
  width:370px;
  z-index:3;
`

export const StyledForm = styled.form.attrs({
  action:'#'
})`
  background:#0002fb;
  text-align:center;
  padding:30px 50px;
  & {
    label{
      margin-left:10px;
      font-size:12px;
    }
  }
`
export const  Input = styled.input`
      padding:15px 20px;
      width:100%;
      margin-bottom:15px;
      border-radius:5px;
      font-size:12px;
`

export const LoginSignUPBtn = styled.button.attrs({
  className:"uppercase",
  type:'submit'
})`
    width:100%;
    background-color:#caf1fd;
    color:#0102fc;
    padding:12px 0;
    margin-top:12px;
    border-radius:5px;
    font-weight:600;
`

export const GoogleSignUPBtn = styled.button.attrs({
  className:"uppercase"
})`
    width:100%;
    background-color:#fff;
    padding:12px 0;
    margin-top:12px;
    border-radius:5px;
    font-weight:600;
    font-size:12px;
    display:flex;
    align-items:center;
    justify-content:space-around;
  &{
   img{
      width:25px;
   }
 }
`

// node Modal

export const NodeModalDiv = styled.div`
 position:absolute;
 width:100%;
 top:0;
 background-color:#535252cf;
 padding:30px;
 z-index:20;
 height:100%;
 cursor: default;	
`

export const NodeInput = styled.input<Half>`
    border:none;
    border-bottom:1px solid #737373;
    padding:5px;
    margin-top:15px;
    font-size:12px;
    &:focus{
      outline:none;
    }
    &::placeholder{
      color:#737373;
      opacity:1;
    }
    ${props => props.half ?`
            width:50%`: `
            width:100%
        `
    }
`
export const NodeForm = styled.form`
 margin-top:10px;
 position:absolute;
 width:40%;
 background:#fff;
 right:20%;
 border-radius:10px;
 display:flex;
 flex-direction:column;
 align-items:center;
 padding:10px 30px;
 &{
   i:nth-child(1){
     font-size:5.2rem;
     color:#a6a6a6;
   }
   button{
     padding:10px 30px;
     border: 2px solid #0002fb;
     border-radius:5px;
     margin-top:2rem;
     margin-bottom:1.5rem;
     color: #0002fb;
   }
 }
`
interface Half { 
  half?: boolean; 
}
