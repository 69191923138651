export const USER_OPEN_SUCCESS = "USER_OPEN_SUCCESS"
export const USER_CLOSE_SUCCESS = "USER_CLOSE_SUCCESS"

export const PLAN_OPEN_SUCCESS = "PLAN_OPEN_SUCCESS"
export const PLAN_CLOSE_SUCCESS = "PLAN_CLOSE_SUCCESS"

export const TEAM_OPEN_SUCCESS = "TEAM_OPEN_SUCCESS"
export const TEAM_CLOSE_SUCCESS = "TEAM_CLOSE_SUCCESS"

export const BILLINGS_OPEN_SUCCESS = "BILLINGS_OPEN_SUCCESS"
export const BILLINGS_CLOSE_SUCCESS = "BILLINGS_CLOSE_SUCCESS"