import { AnyAction } from "@reduxjs/toolkit";
import {
  FORMAT_1000_SEPARATED_CLICKED,
  FORMAT_CURRENCY_CLICKED,
  FORMAT_DECRESE_DECIMAL_CLICKED,
  FORMAT_INCREASE_DECIMAL_CLICKED,
  FORMAT_PERCENTAGE_CLICKED,
  GET_SELECTED_CURRENCY,
} from "redux/constants/Data/FormatConsts";

export const formatAsCurrencyReducer = (
  state = { formatClicked: null },
  action: AnyAction
) => {
  switch (action.type) {
    case FORMAT_CURRENCY_CLICKED:
      return {
        formatClicked:
          state.formatClicked === null ? true : !state.formatClicked,
      };

    default:
      return state;
  }
};

export const formatAsIncreaseDecimalReducer = (
  state = { increaseDecimalClicked: null },
  action: AnyAction
) => {
  switch (action.type) {
    case FORMAT_INCREASE_DECIMAL_CLICKED:
      return {
        increaseDecimalClicked:
          state.increaseDecimalClicked === null
            ? true
            : !state.increaseDecimalClicked,
      };

    default:
      return state;
  }
};

export const formatAsDecreaseDecimalReducer = (
  state = { decreaseDecimal: null },
  action: AnyAction
) => {
  switch (action.type) {
    case FORMAT_DECRESE_DECIMAL_CLICKED:
      return {
        decreaseDecimal:
          state.decreaseDecimal === null ? true : !state.decreaseDecimal,
      };

    default:
      return state;
  }
};

export const formatAsPercentagelReducer = (
  state = { percentage: null },
  action: AnyAction
) => {
  switch (action.type) {
    case FORMAT_PERCENTAGE_CLICKED:
      return {
        percentage: state.percentage === null ? true : !state.percentage,
      };

    default:
      return state;
  }
};

export const formatThousandSeparatorReducer = (
  state = { formatThousandSeparatorClicked: null },
  action: AnyAction
) => {
  switch (action.type) {
    case FORMAT_1000_SEPARATED_CLICKED:
      return {
        formatThousandSeparatorClicked:
          state.formatThousandSeparatorClicked === null
            ? true
            : !state.formatThousandSeparatorClicked,
      };

    default:
      return state;
  }
};

export const getSelectedCurrencyReducer = (
  state = { selectedCurrency: null, allCurr: [] },
  action: AnyAction
) => {
  switch (action.type) {
    case GET_SELECTED_CURRENCY:
      return {
        selectedCurrency: action.payload,
        allCurr: action.allCurr,
      };

    default:
      return state;
  }
};
