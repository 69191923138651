export const HUB_CHART_OPEN_SUCCESS = "HUB_CHART_OPEN_SUCCESS"
export const HUB_CHART_CLOSE_SUCCESS = "HUB_CHART_CLOSE_SUCCESS"


export const GET_SINGLE_CHART_REQUEST = 'GET_SINGLE_CHART_REQUEST'
export const GET_SINGLE_CHART_SUCCESS = 'GET_SINGLE_CHART_SUCCESS'
export const GET_SINGLE_CHART_FAIL = 'GET_SINGLE_CHART_FAIL'


export const SHOW_PUBLISH_SCREEN = "SHOW_PUBLISH_SCREEN"
export const HIDE_PUBLISH_SCREEN = "HIDE_PUBLISH_SCREEN"