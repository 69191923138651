import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_CLOSE_SUCCESS } from 'redux/constants/chatOpenConsts';
import { HIDE_PUBLISH_SCREEN, HUB_CHART_CLOSE_SUCCESS } from 'redux/constants/hubsChartOpenConst';
import { HUB_CLOSE_SUCCESS } from 'redux/constants/hubsConst';
import { BILLINGS_CLOSE_SUCCESS, PLAN_CLOSE_SUCCESS, TEAM_CLOSE_SUCCESS, USER_CLOSE_SUCCESS } from 'redux/constants/SettingConst';
import { ANALYZER_CLOSE_SUCCESS, ANALYZER_OPEN_SUCCESS, CHART_CLOSE_SUCCESS, CHART_OPEN_SUCCESS, DATA_CLOSE_SUCCESS, DATA_OPEN_SUCCESS, DATA_SET_CLOSE_SUCCESS, DATA_SET_OPEN_SUCCESS, SHARING_CLOSE_SUCCESS, SHARING_OPEN_SUCCESS } from 'redux/constants/TopNavOpenConst';
import styled from 'styled-components';
import { RootState } from 'types';


interface Active {
    active?: boolean;
}


const Div = styled.div`
    width: 73%
`

const DropDown = styled.div.attrs({
    className: 'bg-white border border-color-border border-t-0 border-l-0 absolute -left-20 top-16 flex flex-col justify-between'
})`
    width: calc(23% + 80px);
    min-height: 45vh;
    z-index: 33;
`

const Link = styled.p.attrs({
    className: "text-sm text-theme-blue mb-2"
})``

const NavLink = styled.div.attrs({
    className: 'flex flex-col items-center w-24 border-r border-color-border h-full  text-[#929292] justify-center cursor-pointer'
}) <Active>`
${props => props.active ? `
    background-color: #fff;
    color:#000;
  
    ` : `
    background-color: #f7f6f6;
  
    `
    }

    &{
        p{

        }
    }
`
const MyChartDiv = styled.div`
    width: 23%;
`
const TopNav = () => {

    const [show, setShow] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (show) {
            document.body.onclick = function () { setShow(false) }
        }
    }, [show])

    const { dataSet, chart, data, analyzer, sharing } = useSelector((state: RootState) => state.TopNavOpener)

    //  for special options in chart
    const [specialOpts, setSpecialOpts] = useState({ info: false, options: false, library: false })
    const sss = useSelector((state: RootState) => state.nodeTypeSecelction)




    return <>
        <div className='relative'>
            <div className='h-16 z-50 w-screen fixed top-0 border border-color-border border-t-0 flex  items-center'>
                <div className='w-20 h-full bg-white py-3 px-[19px] cursor-pointer border-r border-color-border'>
                    <img className='h-full w-full' src="images/HomeImgs/smallLogo.png" alt="" />
                </div>
                <div className='flex flex-1 items-center h-16 relative'>
                    <MyChartDiv className=' flex items-center h-full bg-white border-r border-b border-color-border'>
                        <p className='pl-12 text-xs italic flex-1'>My Chart 1 <i className="far fa-edit ml-2"></i></p>
                    </MyChartDiv>
                    <Div className=' h-full flex flex-1 border-b border-color-border'>
                        <div className='flex  h-full'>
                            <div className={classNames('flex flex-col items-center w-24 border-r border-color-border h-full  cursor-pointer', "bg-theme-gray  text-[#929292]")}>
                                <p className='text-[10px] uppercase  font-bold mb-2 mt-[8px]'>DataSet</p>
                                <span className={classNames('text-[8px] font-bold px-2 py-1 rounded-[20px] text-white', dataSet ? 'bg-black' : 'bg-[#606060]')}>Electrolux</span>
                            </div>

                            <NavLink active>
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Chart</p>
                                <i className='text-xl fas fa-sitemap'></i>
                            </NavLink>

                            <NavLink >
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Data</p>
                                <i className='text-xl fas fa-table'></i>
                            </NavLink>
                            <NavLink >
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Analyzer</p>
                                <i className='text-xl fas fa-chart-pie'></i>
                            </NavLink>

                            <NavLink >
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Sharing</p>
                                <i className='text-xl fas fa-user-plus'></i>
                            </NavLink>
                        </div>
                        {chart && <>
                            <div className='flex relative bg-[#ebebeb] flex-1 border-r border-color-border justify-center items-center'>
                                <input type="text" className='text-[10px] focus:outline-none px-2 py-1 border h-7 border-color-border bg-white' />
                                <i className="fas cursor-pointer text-[#929292] text-xs pt-[5px] pl-[6px] w-7 h-7 border border-color-border fa-search"></i>
                            </div>

                            <NavLink active={specialOpts.info}>
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Info</p>
                                <i className="fas text-xl fa-info-circle"></i>
                            </NavLink>
                            <NavLink active={specialOpts.options}>
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Options</p>
                                <i className='text-xl fas fa-cog'></i>
                            </NavLink>
                            <NavLink active={specialOpts.library}>
                                <p className='text-[10px] mb-1 uppercase  font-bold'>Library</p>
                                <i className='text-xl fas fa-book-open'></i>
                            </NavLink>
                        </>}

                    </Div>
                    {show && <DropDown >
                        <div className='flex justify-between px-12 pt-8'>
                            <div>
                                <a target='_blank' href="/"><Link>Home</Link></a>
                                <a target='_blank' href="/about"><Link>About</Link></a>
                                <a target='_blank' href="/latest"><Link>Our Blog</Link></a>
                            </div>

                            <div>
                                <a target='_blank' href="/privacy"><Link>Privacy Policy</Link></a>
                                <a target='_blank' href="/terms"><Link>Terms Of Services</Link></a>
                            </div>
                        </div>
                        <div className='flex flex-col items-center mt-4 mx-8 py-8  rounded-lg bg-[#f6f6f6]'>
                            <img className='w-3/5' src="images/HomeImgs/headerLogo.png" alt="" />
                            <div className="text-center">
                                <p className='font-bold text-xs mb-1 mt-4'>v 1.0</p>
                                <p className='text-xs'>&copy; Copyright 2022</p>
                            </div>
                        </div>
                        <div className='border-t  py-3 border-color-border'>
                            <p className='text-center text-xs font-bold flex w-full justify-center items-center'> Made with <span><img className='w-5 mx-[2px]' src="images/homeImgs/heart.gif" alt="" /></span> in Sweden</p>
                        </div>
                    </DropDown>}
                </div>
                <div className='absolute h-full z-[999] w-full bg-black opacity-20 top-0 left-0'></div>
            </div>

        </div>
    </>;
};

export default TopNav;