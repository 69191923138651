import { AnyAction } from "redux"
import { CHAT_CLOSE_SUCCESS, CHAT_OPEN_SUCCESS, GET_SINGLE_CHAT_FAIL, GET_SINGLE_CHAT_REQUEST, GET_SINGLE_CHAT_SUCCESS } from "redux/constants/chatOpenConsts"
import { ChatDispatchTypes } from '../actions/ChatOpenActions'



interface State {
    chatOpen: boolean,
    chatId?: string | number,
    chatType?: "chartShare" | "support" | "admin",
}

const defaultState : State = {
    chatOpen: false,
}

export const chatReducer = (state: State = defaultState, action:ChatDispatchTypes):State => {
    switch(action.type){
        case GET_SINGLE_CHAT_REQUEST:
            return{
                chatOpen: false
            }
        case GET_SINGLE_CHAT_SUCCESS:
            return {
                chatOpen: action.payload.chatOpen,
                chatId:action.payload.chatId,
                chatType:action.payload.chatType
            }

        case GET_SINGLE_CHAT_FAIL:
            return {
                chatOpen: false
            }

        case CHAT_CLOSE_SUCCESS:
            return {
                chatOpen: false
            }
            
            default:
                return state
    }
}