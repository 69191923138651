/**
 * Asynchronously loads the component for HomePage
 */

 import { lazyLoad } from 'utils/loadable';

 export const VideosPage = lazyLoad(
   () => import('./index'),
   module => module.VideosPage,
 );
 