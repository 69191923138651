/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "@reduxjs/toolkit";
import { dragAndDropImageReducer } from "redux/reducers/Chart/DragAndDropReducer";
import { compactReducer } from "redux/reducers/chartFormating/compactReducer";
import { rotateReducer } from "redux/reducers/chartFormating/rotateReducer";
import { chatReducer } from "redux/reducers/ChatReducer";
import {
  formatAsCurrencyReducer,
  formatAsDecreaseDecimalReducer,
  formatAsIncreaseDecimalReducer,
  formatAsPercentagelReducer,
  formatThousandSeparatorReducer,
  getSelectedCurrencyReducer,
} from "redux/reducers/data/FormatReducers";
import { singleRowSecelctionFromDataReducer } from "redux/reducers/data/getDataRowsReducer";
import { uploadCsvReducer } from "redux/reducers/data/uploadCsvReducer";
import {
  hubChartOpenReducer,
  hubsReducer,
  publishScreenShowReducers,
} from "redux/reducers/HubsReducers";
import { LeftNavOpenerReducer } from "redux/reducers/LeftNavOpenReducers";
import { SettingScreenOpenerReducer } from "redux/reducers/SettingsReducer";
import { editNodesReducers } from "redux/reducers/TopNavReducers/ChartReducer";
import {
  formatNodeReducer,
  fromatLinkReducer,
  fromatTextReducer,
} from "redux/reducers/TopNavReducers/formatChartReducer";
import {
  chartTypeSecelctionReducer,
  subChartTypeSecelctionReducer,
} from "redux/reducers/TopNavReducers/SelectChartType";
import { singleNodeSecelctionFromChartReducer } from "redux/reducers/TopNavReducers/SelectSingleNodeFromChartReducer";
import { nodeTypeSecelctionReducer } from "redux/reducers/TopNavReducers/seletNodeType";
import { TopNavOpenerReducerLeft } from "redux/reducers/TopNavReducers/TopNavLeftOpen";
import { TopNavOpenerReducer } from "redux/reducers/TopNavReducers/TopNavOpenReducers";
import { TopNavOpenerReducerRight } from "redux/reducers/TopNavReducers/TopNavRightOpen";

import { InjectedReducersType } from "utils/types/injector-typings";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  // if (Object.keys(injectedReducers).length === 0) {
  //   return state => state;
  // } else {
  return combineReducers({
    ...injectedReducers,
    LeftNavOpener: LeftNavOpenerReducer,
    TopNavOpener: TopNavOpenerReducer,
    TopNavOpeneLeft: TopNavOpenerReducerLeft,
    TopNavOpeneRight: TopNavOpenerReducerRight,
    Chat: chatReducer,
    Hub: hubsReducer,
    HubChart: hubChartOpenReducer,
    publishScreen: publishScreenShowReducers,
    SettingScreenOpener: SettingScreenOpenerReducer,
    nodeTypeSecelction: nodeTypeSecelctionReducer,
    editNodes: editNodesReducers,
    chartTypeSecelction: chartTypeSecelctionReducer,
    singleNodeSecelctionFromChart: singleNodeSecelctionFromChartReducer,
    formatNode: formatNodeReducer,
    fromatLink: fromatLinkReducer,
    fromatText: fromatTextReducer,
    compact: compactReducer,
    rotate: rotateReducer,
    dragAndDropImage: dragAndDropImageReducer,
    subChartTypeSecelction: subChartTypeSecelctionReducer,
    singleRowSecelctionFromData: singleRowSecelctionFromDataReducer,
    uploadCsv: uploadCsvReducer,
    formatAsCurrency: formatAsCurrencyReducer,
    formatAsIncreaseDecimal: formatAsIncreaseDecimalReducer,
    formatAsDecreaseDecimal: formatAsDecreaseDecimalReducer,
    formatAsPercentagel: formatAsPercentagelReducer,
    formatThousandSeparator: formatThousandSeparatorReducer,
    getSelectedCurrency: getSelectedCurrencyReducer,
  });
}
// }
