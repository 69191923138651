import { AnyAction } from "redux"


export const COMPACT_CLICK = 'COMPACT_CLICK'

interface State{
    isCompactOpen:boolean
}

export const compactReducer = (state:State={isCompactOpen:false}, action:AnyAction):State=> {
    switch(action.type){

        case COMPACT_CLICK:
            return{
                isCompactOpen:!state.isCompactOpen
            }

        default:
            return state;
    }
}