import styled from "styled-components";
import tw from "twin.macro";


export const ImgsFooter = styled.img.attrs({
    className:'w-[10%]'
})`
    
`

export const P = styled.p.attrs({
    className:'font-bold text-sm lg:mr-4 mr-4 lg:ml-0 ml-4 text-theme-blue cursor-pointer lg:text-left text-center'
})``

export const FooterBoxLast = styled.div`
    box-shadow: 0px 0px 20px 0px #e1e1e1;
    &{
        p{
            ${tw`lg:text-xs text-[10px]`}
        }
    }
`

export const Modal = styled.div.attrs({
    className:"absolute  w-full h-full bg-[#000]/90 left-0 top-0 px-4 lg:px-24 py-12 lg:py-20"
})``

export const Pricing = styled.div.attrs({
    className:""
})`

`