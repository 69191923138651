import { Action, AnyAction } from "redux";

interface State {
  edit: boolean;
}

export const EDIT_CHANGE = "EDIT_CHANGE";

export const editNodesReducers = (
  state: State = { edit: false },
  action: any
): State => {
  switch (action.type) {
    case EDIT_CHANGE:
      return {
        edit: !state.edit,
      };

    default:
      return state;
  }
};
