import { AnyAction } from "redux";

export const ROTATE_CLICK = "ROTATE_CLICK";
export const ROTATE_CLICK_FALSE = "ROTATE_CLICK_FALSE";

interface State {
  isRotateClicked: string;
}

const arr = ["bottom", "left", "top", "right"];

export const rotateReducer = (
  state: State = { isRotateClicked: "bottom" },
  action: AnyAction
): State => {
  switch (action.type) {
    case ROTATE_CLICK:
      console.log(
        "arr.indexOf(state.isRotateClicked)",
        arr.indexOf(state.isRotateClicked)
      );
      const index = arr.indexOf(state.isRotateClicked);

      return {
        isRotateClicked: arr[index !== 3 ? index + 1 : 0],
      };

    default:
      return state;
  }
};
