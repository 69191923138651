import { AnyAction } from "redux"
import {  HUBS_OPEN_SUCCESS, INBOX_OPEN_SUCCESS, NOTES_OPEN_SUCCESS, SETTINGS_OPEN_SUCCESS, TUTORIALS_OPEN_SUCCESS } from "redux/constants/LeftNavOpenConst"


interface State {
    tutorial:boolean
    inbox:boolean
    hubs:boolean
    settings:boolean
    notes:boolean
}

const defaultState : State ={
    tutorial: true,
    inbox: false,
    hubs:false,
    settings:false,
    notes:false
}

export const LeftNavOpenerReducer = (state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case TUTORIALS_OPEN_SUCCESS :
            return {
                tutorial: !state.tutorial,
                inbox: false,
                hubs:false,
                settings:false,
                notes:false
            }

        case INBOX_OPEN_SUCCESS :
            return {
                tutorial: false,
                inbox: !state.inbox,
                hubs:false,
                settings:false,
                notes:false
            }

        case HUBS_OPEN_SUCCESS :
            return {
                tutorial: false,
                inbox: false,
                hubs:!state.hubs,
                settings:false,
                notes:false
            }
        case SETTINGS_OPEN_SUCCESS :
            return {
                tutorial: false,
                inbox: false,
                hubs:false,
                settings:!state.settings,
                notes:false
            }

            case NOTES_OPEN_SUCCESS :
                return {
                    tutorial: false,
                    inbox: false,
                    hubs:false,
                    settings:false,
                    notes:!state.notes
                }

            default:
                return state
    }
}