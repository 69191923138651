import { Action, AnyAction } from "redux";
import {
  UPLOAD_CSV_FAIL,
  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
} from "redux/constants/Data/uploadCSVconst";

export const uploadCsvReducer = (
  state = { loading: false, csvArr: [], error: null },
  action: AnyAction
) => {
  switch (action.type) {
    case UPLOAD_CSV_REQUEST:
      return {
        loading: true,
      };
    case UPLOAD_CSV_SUCCESS:
      return {
        loading: false,
        csvArr: action.payload,
      };
    case UPLOAD_CSV_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
