import { AnyAction } from "redux"
import { DRAG_FAILED, DRAG_INITIATED, DRAG_SUCESSFUL } from "redux/constants/Chart/DragAndDropImg"



interface State{
    loading:boolean
    image:string | undefined
}

const defaultState:State = {
    loading:false,
    image:undefined
}

export const dragAndDropImageReducer = (state:State=defaultState, action:AnyAction):State => {
    switch(action.type){
        case DRAG_INITIATED:
            return{
                loading:true,
                image:state.image
            }

        case DRAG_SUCESSFUL:
            return{
                loading:true,
                image:action.payload
            }

        case DRAG_FAILED:
            return{
                loading:false,
                image:undefined
            }
        
        default:
            return state;
    }
}