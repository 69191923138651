import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components"
import tw from "twin.macro"
import { GoogleSignUPBtn, Input, LoginDiv, LoginSignUp, LoginSignUPBtn, StyledForm, StyledHeader } from './style';


const Header = () => {

    const [Login, Register] = useState(true);
    const [loginFormShow, setLoginFormShow] = useState(false)

    useEffect(() => {
        if(loginFormShow){
            document.body.onclick=function () {setLoginFormShow(false)}
        }
    }, [loginFormShow])

    console.log(loginFormShow);

    const showHideForm = (e:any) => {
        setLoginFormShow(!loginFormShow);
        e.stopPropagation()
    }
    

  return (
        <>
            <StyledHeader>
                    <Link to='/'><img src="images/HomeImgs/headerLogo.png" alt="" /></Link>
                    <LoginSignUp onClick={showHideForm}><i className="fas fa-arrow-right"></i>Login / SingUP</LoginSignUp>
            </StyledHeader>

            {loginFormShow && <LoginDiv onClick={(e) => e.stopPropagation()}>
                <p className='uppercase py-4 text-center font-semibold text-sm'>{Login?'Login to your account': 'Create new account'} </p>
                <StyledForm>
                    <Input type="text" placeholder='E-mail Address' />
                    <Input type="password" placeholder='Password' />
                    <div className='text-left flex items-center my-2'>
                        {Login ? <div className='flex items-center'>
                            <input className='w-4 h-4' type="checkbox" id='rememberme' />
                            <label className='text-white' htmlFor="rememberme">Remember Me</label>
                        </div> :
                            <div className='flex items-center'>
                                <input className='w-4 h-4' type="checkbox" id='rememberme' />
                                <label className='text-white font-semibold' htmlFor="rememberme">I agree to the <a href="#"><span className='text-amber-300'>Terms and Condition</span></a></label>
                            </div> 
                        }
                        {Login&& <p className='text-right cursor-pointer flex-1 text-white text-xs	text-amber-300	'>Forgot Password ?</p>}
                    </div>
                    <LoginSignUPBtn>{Login ?"LOGIN":"Signup"}</LoginSignUPBtn>
                    <GoogleSignUPBtn>{Login?"Login":"Signup"} with google <img src="images/HomeImgs/Google.png" alt="" /></GoogleSignUPBtn>
                </StyledForm>
                <p className=' py-4 text-center font-semibold '><span className='text-xs font-normal pr-4'>{Login?"Don't Have an account?":"Already have an account?"}</span><span onClick={()=> Register(!Login)} className='cursor-pointer uppercase text-[#0102fc]'>{Login?"SIGNUP": "LOGIN"}</span></p>
            </LoginDiv>}
        </>
    );
};

export default Header;
