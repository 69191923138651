import styled from "styled-components";

export const BgGridDataSet = styled.div`
background:

        linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
        linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
        linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
        linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
        #f2f2f2;
    background-size:
        8px 8px,
        8px 8px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px;
  height: calc(100vh - 64px);
  width: calc(100% - 30rem)
`
interface Active{
  activeFitToScreen?: boolean
  backgroundImage?:any
}

export const BgGridChart = styled.div<Active>`

    
  ${props => props.backgroundImage ? `
    background: ${props.backgroundImage} no-repeat;
    background-size: 100%;
  `:`
  background:

  linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
  linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
  linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
  linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
  #f2f2f2;
background-size:
  8px 8px,
  8px 8px,
  80px 80px,
  80px 80px,
  80px 80px,
  80px 80px,
  80px 80px,
  80px 80px;
  `

  }

  ${props => props.activeFitToScreen ?`
      height: 100vh;
      width: 100vw;
        `: `
      height: calc(100vh - 64px);
      width: 100% ;
            `
    }
`