export const FORMATTING_REQ = "FORMATTING_REQ";
export const FORMATTING_SUCC = "FORMATTING_SUCC";

export const TEXT_FORMATTING_REQ = "TEXT_FORMATTING_REQ";
export const TEXT_FORMATTING_SUCC = "TEXT_FORMATTING_SUCC";

export const LINK_FORMATTING_REQ = "LINK_FORMATTING_REQ";
export const LINK_FORMATTING_SUCC = "LINK_FORMATTING_SUCC";

export const SAVE_UNDO_REQ = "SAVE_UNDO_REQ";
export const SAVE_UNDO_SUCC = "SAVE_UNDO_SUCC";
