import { AnyAction } from "redux"
import { BILLINGS_CLOSE_SUCCESS, BILLINGS_OPEN_SUCCESS, PLAN_CLOSE_SUCCESS, PLAN_OPEN_SUCCESS, TEAM_CLOSE_SUCCESS, TEAM_OPEN_SUCCESS, USER_CLOSE_SUCCESS, USER_OPEN_SUCCESS } from "redux/constants/SettingConst"



interface State {
    user:boolean
    plan:boolean
    team:boolean
    billing:boolean
}


const defaultState : State ={
    user:false,
    plan:false,
    team:false,
    billing:false
}

export const SettingScreenOpenerReducer = (state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case USER_OPEN_SUCCESS :
            return {
                user:!state.user,
                plan:false,
                team:false,
                billing:false
            }
        case PLAN_OPEN_SUCCESS :
            return {
                user:false,
                plan:!state.plan,
                team:false,
                billing:false
            }

        case TEAM_OPEN_SUCCESS :
            return {
                user:false,
                plan:false,
                team:!state.team,
                billing:false
            }
        case BILLINGS_OPEN_SUCCESS :
            return {
                user:false,
                plan:false,
                team:false,
                billing:!state.billing
            }

            case USER_CLOSE_SUCCESS :
                return defaultState
            case PLAN_CLOSE_SUCCESS :
                return defaultState
    
            case TEAM_CLOSE_SUCCESS :
                return defaultState
            case BILLINGS_CLOSE_SUCCESS :
                return defaultState
            default:
                return state
    }
}