import { Action, AnyAction } from "redux";
import {
  SELECT_DATAS_FAIL,
  SELECT_DATAS_REQUEST,
  SELECT_DATAS_SUCCESS,
} from "redux/constants/Data/selectDatasConst";

export const singleRowSecelctionFromDataReducer = (
  state = { loading: false, rows: [], error: null },
  action: AnyAction
) => {
  switch (action.type) {
    case SELECT_DATAS_REQUEST:
      return {
        loading: true,
      };
    case SELECT_DATAS_SUCCESS:
      return {
        loading: false,
        rows: action.payload,
      };
    case SELECT_DATAS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
