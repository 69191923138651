export const DATA_SET_OPEN_SUCCESS = "DATA_SET_OPEN_SUCCESS"
export const DATA_SET_CLOSE_SUCCESS = "DATA_SET_CLOSE_SUCCESS"

export const CHART_OPEN_SUCCESS = "CHART_OPEN_SUCCESS"
export const CHART_CLOSE_SUCCESS = "CHART_CLOSE_SUCCESS"

export const DATA_OPEN_SUCCESS = "DATA_OPEN_SUCCESS"
export const DATA_CLOSE_SUCCESS = "DATA_CLOSE_SUCCESS"

export const ANALYZER_OPEN_SUCCESS = "ANALYZER_OPEN_SUCCESS"
export const ANALYZER_CLOSE_SUCCESS = "ANALYZER_CLOSE_SUCCESS"

export const SHARING_OPEN_SUCCESS = "SHARING_OPEN_SUCCESS"
export const SHARING_CLOSE_SUCCESS = "SHARING_CLOSE_SUCCESS"





export const DATA_SET_OPEN_SUCCESS2 = "DATA_SET_OPEN_SUCCESS2"
export const DATA_SET_CLOSE_SUCCESS2 = "DATA_SET_CLOSE_SUCCESS2"

export const CHART_OPEN_SUCCESS2 = "CHART_OPEN_SUCCESS2"
export const CHART_CLOSE_SUCCESS2 = "CHART_CLOSE_SUCCESS2"

export const DATA_OPEN_SUCCESS2 = "DATA_OPEN_SUCCESS2"
export const DATA_CLOSE_SUCCESS2 = "DATA_CLOSE_SUCCESS2"

export const ANALYZER_OPEN_SUCCESS2 = "ANALYZER_OPEN_SUCCESS2"
export const ANALYZER_CLOSE_SUCCESS2 = "ANALYZER_CLOSE_SUCCESS2"

export const SHARING_OPEN_SUCCESS2 = "SHARING_OPEN_SUCCESS2"
export const SHARING_CLOSE_SUCCESS2 = "SHARING_CLOSE_SUCCESS2"





export const DATA_SET_OPEN_SUCCESS3 = "DATA_SET_OPEN_SUCCESS3"
export const DATA_SET_CLOSE_SUCCESS3 = "DATA_SET_CLOSE_SUCCESS3"

export const CHART_OPEN_SUCCESS3 = "CHART_OPEN_SUCCESS3"
export const CHART_CLOSE_SUCCESS3 = "CHART_CLOSE_SUCCESS3"

export const DATA_OPEN_SUCCESS3 = "DATA_OPEN_SUCCESS3"
export const DATA_CLOSE_SUCCESS3 = "DATA_CLOSE_SUCCESS3"

export const ANALYZER_OPEN_SUCCESS3 = "ANALYZER_OPEN_SUCCESS3"
export const ANALYZER_CLOSE_SUCCESS3 = "ANALYZER_CLOSE_SUCCESS3"

export const SHARING_OPEN_SUCCESS3 = "SHARING_OPEN_SUCCESS3"
export const SHARING_CLOSE_SUCCESS3 = "SHARING_CLOSE_SUCCESS3"