import React from "react";
import OrganizationChart from "./components/ChartContainer";
import NodeTemplate from "./NodeTemplate";

export const ds = {
  id: "n1",
  data: {
    name: "Robin Anderson",
    position: "Financial Analyst",
    email: "r_anderson@gmail.com",
    phone: "+46 767 878 9897",
    department: "finance",
    location: "sweden",
    imgUrl: "images/HomeImgs/user1.jpeg",
  },
  children: [
    {
      id: "n2",
      data: {
        name: "Shrinivas Sharma",
        position: "CEO",
        email: "shrinivas@gmail.com",
        phone: "+91 123 456 7890",
        department: "business",
        location: "india",
        imgUrl: "images/HomeImgs/user2.jpeg",
      },
      children: [
        {
          id: "n4",
          data: {
            name: "Shrinivas Sharma",
            position: "CEO",
            email: "shrinivas@gmail.com",
            phone: "+91 123 456 7890",
            department: "business",
            location: "india",
            imgUrl: "images/HomeImgs/user2.jpeg",
          },
        },
        {
          id: "n5",
          data: {
            name: "Robin Anderson",
            position: "CFO",
            email: "r_anderson@gmail.com",
            phone: "+46 767 878 9897",
            department: "finance",
            location: "sweden",
            imgUrl: "images/HomeImgs/user1.jpeg",
          },
        },
      ],
    },

    {
      id: "n3",
      data: {
        name: "Robin Anderson",
        position: "CFO",
        email: "r_anderson@gmail.com",
        phone: "+46 767 878 9897",
        department: "finance",
        location: "sweden",
        imgUrl: "images/HomeImgs/user1.jpeg",
      },
      children: [
        {
          id: "n6",
          data: {
            name: "Shrinivas Sharma",
            position: "CEO",
            email: "shrinivas@gmail.com",
            phone: "+91 123 456 7890",
            department: "business",
            location: "india",
            imgUrl: "images/HomeImgs/user2.jpeg",
          },
        },
        {
          id: "n7",
          data: {
            name: "Robin Anderson",
            position: "CFO",
            email: "r_anderson@gmail.com",
            phone: "+46 767 878 9897",
            department: "finance",
            location: "sweden",
            imgUrl: "images/HomeImgs/user1.jpeg",
          },
        },
        {
          id: "n8",
          data: {
            name: "Shrinivas Sharma",
            position: "CEO",
            email: "shrinivas@gmail.com",
            phone: "+91 123 456 7890",
            department: "business",
            location: "india",
            imgUrl: "images/HomeImgs/user2.jpeg",
          },
        },
      ],
    },
  ],
};
const DragDropChart = () => {
  return (
    <OrganizationChart
      NodeTemplate={NodeTemplate}
      zoom={true}
      datasource={ds}
      multipleSelect={false}
      draggable={true}
    />
  );
};

export default DragDropChart;
