import { AnyAction } from "redux"
import {  } from "redux/constants/LeftNavOpenConst"
import { ANALYZER_CLOSE_SUCCESS, ANALYZER_OPEN_SUCCESS, CHART_CLOSE_SUCCESS, CHART_OPEN_SUCCESS, DATA_CLOSE_SUCCESS, DATA_OPEN_SUCCESS, DATA_SET_CLOSE_SUCCESS, DATA_SET_OPEN_SUCCESS, SHARING_CLOSE_SUCCESS, SHARING_OPEN_SUCCESS } from "redux/constants/TopNavOpenConst"


interface State {
    dataSet:boolean
    chart:boolean
    data:boolean
    analyzer:boolean
    sharing:boolean
}

const defaultState : State ={
    dataSet: true,
    chart: false,
    data:false,
    analyzer:false,
    sharing:false
}

export const TopNavOpenerReducer = (state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case DATA_SET_OPEN_SUCCESS :
            return {
                dataSet: true,
                chart: false,
                data:false,
                analyzer:false,
                sharing:false
            }
        case CHART_OPEN_SUCCESS :
            return {
                dataSet: false,
                chart: true,
                data:false,
                analyzer:false,
                sharing:false
            }

        case DATA_OPEN_SUCCESS :
            return {
                dataSet: false,
                chart: false,
                data:true,
                analyzer:false,
                sharing:false
            }
        case ANALYZER_OPEN_SUCCESS :
            return {
                dataSet: false,
                chart: false,
                data:false,
                analyzer:true,
                sharing:false
            }

        case SHARING_OPEN_SUCCESS :
            return {
                dataSet: false,
                chart: false,
                data:false,
                analyzer:false,
                sharing:true
            }

            default:
                return state
    }
}