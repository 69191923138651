import React from 'react'

const NodeTemplate = ({nodeData}) => {
    const d = nodeData;
  return (
    <>
<div className=' w-[250px] p-3 border hover:border-theme-blue bg-white border-transparent rounded-[15px]  border-[3px] shadow-md h-[140px]'>
            <div className='flex justify-between  w-full  '>
                    <div className="text-black">
                        <div className=" text-[13px] font-semibold">{d.data.name} </div>
                        <div className=" text-[11px] opacity-70 mt-0.5 font-medium">{d.data.position } </div>
                        <div className='mt-2'>
                            <div className=" text-[11px] opacity-70 mt-0.5 font-medium">{d.data.email } </div>
                            <div className=" text-[11px] opacity-70 mt-0.5 font-medium">{d.data.phone } </div>
                        </div>

                    </div>
                    <img className='w-10 h-10 mr-2 rounded-[10px]' src={d.data.imgUrl} />
            </div>
            <div className='flex pt-4 justify-between items-center'>
                <p className='text-[10px] font-medium uppercase bg-theme-gray px-2 rounded-full py-0.5'>{d.data.department}</p>
                <p className='text-[10px] font-medium uppercase mr-2'>{d.data.location}</p>
            </div>
        </div>
    </>
  )
}

export default NodeTemplate