import React from 'react'
import { useHistory } from 'react-router-dom'
import { BgGridChart } from 'styles/BgGrid'
import DragDropChart from './DragNDrop'
import LeftNav from './staticNavigation/LeftNav'
import TopNav from './staticNavigation/TopNav'

const Rearrange = () => {
    const history = useHistory()
  return (
    <>
        <BgGridChart activeFitToScreen className='fixed w-full h-[100vh]'>
          <TopNav/>
          <div className='mt-16 flex '>
            <LeftNav/>
            

            <div className='w-[calc(100vw-81px)]'>

              <div className='absolute z-[999] top-20 left-32 bg-white border rounded-lg'>
                  <button onClick={ () => {history.push('/software')}} className='text-xs px-4 py-2'>SAVE</button>
              </div>

                <DragDropChart/>
              </div>
              </div>
        </BgGridChart>
    </>
  )
}

export default Rearrange