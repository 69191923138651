import { Action, AnyAction } from "redux";
import {
  FORMATTING_REQ,
  FORMATTING_SUCC,
  LINK_FORMATTING_REQ,
  LINK_FORMATTING_SUCC,
  TEXT_FORMATTING_REQ,
  TEXT_FORMATTING_SUCC,
} from "redux/constants/Chart/Formatting";

interface RGB {
  r: string | number;
  g: number | string;
  b: string | number;
  a: number | string;
}

interface State {
  textBg: RGB;
  textColor: RGB;
  nodeBg: RGB;
  fontFamily: { value: string; label: string };
  loading: boolean;
}

const defaultState: State = {
  loading: false,
  textBg: { r: 255, g: 255, b: 255, a: 1 },
  textColor: {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },
  nodeBg: { r: 255, g: 255, b: 255, a: 1 },
  fontFamily: { value: "def", label: "Montserrat" },
};

export const formatNodeReducer = (
  state: State = defaultState,
  action: AnyAction
): State => {
  switch (action.type) {
    case FORMATTING_REQ:
      return {
        ...state,
        loading: true,
      };

    case FORMATTING_SUCC:
      return {
        loading: false,
        textBg: action.payload.textBg,
        textColor: action.payload.textColor,
        nodeBg: action.payload.nodeBg,
        fontFamily: action.payload.fontFamily,
      };

    default:
      return state;
  }
};

interface StateText {
  nameColor: RGB;
  positionColor: RGB;
  emailColor: RGB;
  phoneColor: RGB;
  departmentColor: RGB;
  countryColor: RGB;
  loading: boolean;
}

const black: RGB = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};
const defaultStateText: StateText = {
  nameColor: black,
  positionColor: black,
  emailColor: black,
  phoneColor: black,
  departmentColor: black,
  countryColor: black,
  loading: false,
};
export const fromatTextReducer = (
  state: StateText = defaultStateText,
  action: AnyAction
): StateText => {
  switch (action.type) {
    case TEXT_FORMATTING_REQ:
      return {
        ...state,
        loading: true,
      };

    case TEXT_FORMATTING_SUCC:
      return {
        loading: false,
        nameColor: black,
        positionColor: action.payload.positionColor,
        emailColor: action.payload.emailColor,
        phoneColor: action.payload.phoneColor,
        departmentColor: action.payload.departmentColor,
        countryColor: action.payload.countryColor,
      };

    default:
      return state;
  }
};

export const fromatLinkReducer = (
  state = { linkStyle: { color: black, style: "solid" } },
  action: AnyAction
) => {
  switch (action.type) {
    case LINK_FORMATTING_REQ:
      return {
        ...state,
      };

    case LINK_FORMATTING_SUCC:
      return {
        loading: false,
        linkStyle: action.payload,
      };

    default:
      return state;
  }
};
