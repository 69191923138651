import React from 'react';
import { useHistory } from 'react-router-dom';
import { BgGridChart } from 'styles/BgGrid';
import LeftNav from '../Rearrange/staticNavigation/LeftNav';
import TopNav from '../Rearrange/staticNavigation/TopNav';
import Graph from './CoNode2';
import "./index.css";


const treeData = [
    {
        name: "Root",
        parent: "null",
        children: [
            {
                name: "Level 2: A",
                parent: "Top Level",
                children: [
                    {
                        name: "A1",
                        parent: "Level 2: A",
                    },
                    {
                        name: "A2",
                        parent: "Level 2: A",
                    },
                ],
            },
            {
                name: "Level 2: B",
                parent: "Top Level",
            },
        ],
    },
];

function transformTreeToGraph(tree) {
    let nodes: any = [];
    let links: any = [];

    function traverse(node, parentId = null) {
        nodes.push({ id: node.name, label: node.name });

        if (parentId) {
            links.push({ source: parentId, target: node.name });
        }

        if (node.children) {
            node.children.forEach(child => traverse(child, node.name));
        }
    }

    traverse(tree[0]);
    return { nodes, links };
}

function CoNodeTest2() {
    const { nodes, links } = transformTreeToGraph(treeData);
    const history = useHistory()

    return <BgGridChart activeFitToScreen className='fixed w-full h-[100vh]'>
        <TopNav />
        <div className='mt-16 flex '>
            <LeftNav />


            <div className='w-[calc(100vw-81px)]'>

                <div className='absolute z-[999] top-20 left-32 bg-white border rounded-lg'>
                    <button onClick={() => { history.push('/software') }} className='text-xs px-4 py-2'>SAVE</button>
                </div>

                <Graph />
            </div>
        </div>
    </BgGridChart>;
}

export default CoNodeTest2;
