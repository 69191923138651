import { Action, AnyAction } from "redux"
import { REMOVE_ALL_NODE, SELECT_ALL_NODE, SELECT_SINGLE_NODE_FAIL, SELECT_SINGLE_NODE_REQ, SELECT_SINGLE_NODE_SUCC } from "redux/constants/Chart/SingleChartInfo"

interface State {
    nodeIds: (string | number)[] ,
    loading:boolean,
    open?:boolean

}

const defaultState : State = {
    loading:false,
    nodeIds:[],
    open:false
}


export const singleNodeSecelctionFromChartReducer = (state: State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case SELECT_SINGLE_NODE_REQ:
            return{
                nodeIds:state.nodeIds,
                loading:true,
            }
        case SELECT_SINGLE_NODE_SUCC:
            let arr:(string | number)[] = [...state.nodeIds]
            if(arr.includes(action.payload.nodeId)){
                arr = arr.filter(a=> a!==action.payload.nodeId)
            } else{
                arr.push(action.payload.nodeId)
            }
            console.log('state', arr);


            return {
                nodeIds:arr,
                loading:false,
                open: ((action.payload.nodeId === action.payload.clickedId) && !state.open  )
            }


        case SELECT_SINGLE_NODE_FAIL:
            return {
                loading:false,
                nodeIds:state.nodeIds
            }

            case REMOVE_ALL_NODE:
                return{
                    loading:false,
                    nodeIds:[]
                }

            default:
                return state
    }
}