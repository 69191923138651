import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { HUBS_OPEN_SUCCESS, INBOX_OPEN_SUCCESS, NOTES_OPEN_SUCCESS, SETTINGS_OPEN_SUCCESS, TUTORIALS_OPEN_SUCCESS } from 'redux/constants/LeftNavOpenConst';
import { RootState } from 'types';


const MainDiv= styled.div`
    height: calc(100vh - 64px)
`


interface Active { 
  active?: boolean; 
}

const NavItem = styled.div.attrs({
  className:"flex border-b border-r border-color-border px-2 py-3 flex-col items-center cursor-pointer"
})<Active>`
 ${
  props => props.active ? `
  background-color: #F7F6F6;
  border-right:1px solid transparent ;

  ` : `
  background-color: #EBEBEB;

  `
 }
 & {
   i{
     
     ${
       props => props.active ? `
        color: #000;
       ` :`
        color:#929292;
       `
     }
   }
   p{
     ${tw`text-[11px] mt-1`}
     ${
      props => props.active ? `
       font-weight: 700;
      ` :`
      font-weight:400;
      `
    }
   }
 }
`
const LeftNav = () => {
  const {tutorial, inbox, hubs, settings, notes} = useSelector((state: RootState)=> state.LeftNavOpener)



  const [reqOpen, setReqOpen] = useState(false)
  const dispatch = useDispatch()


  useEffect(() => {
    if(reqOpen){
        document.body.onclick=function () {setReqOpen(false)}
    }
}, [reqOpen])



  document.onclick
  return <>
        <MainDiv className='flex border-b relative z-50 border-color-border border-t-0 flex-col w-[81px] fixed '>
              <NavItem >
                    <i className="far fa-play-circle"></i>
                    <p className=''>Tutorial</p>
              </NavItem>

              <NavItem >
                    <i className="far fa-envelope"></i>
                    <p className=''>Inbox</p>
              </NavItem>

              <NavItem >
                    <i className="fas fa-users"></i>
                    <p className=''>Hubs</p>
              </NavItem>

              <NavItem >
                    <i className="fas fa-sticky-note"></i>
                    <p className=''>Notes</p>
              </NavItem>

              <NavItem >
                    <i className="fas fa-user-cog"></i>
                    <p className=''>Settings</p>
              </NavItem>

              <div className='flex justify-center border-r border-color-border  pb-16 items-end h-full bottom-16 bg-[#EBEBEB]  left-3'>
                    <i  className={classNames("text-3xl w-full text-center border-y border-y-transparent py-3 cursor-pointer  far fa-question-circle", reqOpen ? "bg-white text-black border-y border-y-color-border" : "text-gray-400")}></i>
              </div>

              <div className='absolute h-full w-full bg-black opacity-20 top-0 left-0'></div>
        </MainDiv>
  </>;
};

export default LeftNav;
