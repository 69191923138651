import { AnyAction } from "redux"
import { hubChartDispatchTypes } from "redux/actions/HubsChartAct"
import { HubDispatchTypes } from "redux/actions/HubsOpenAct"
import { GET_SINGLE_CHART_FAIL, GET_SINGLE_CHART_REQUEST, GET_SINGLE_CHART_SUCCESS, HIDE_PUBLISH_SCREEN, HUB_CHART_CLOSE_SUCCESS, SHOW_PUBLISH_SCREEN } from "redux/constants/hubsChartOpenConst"
import { GET_SINGLE_HUB_FAIL, GET_SINGLE_HUB_REQUEST, GET_SINGLE_HUB_SUCCESS, HUB_CLOSE_SUCCESS } from "redux/constants/hubsConst"


interface State {
    hubOpen: boolean,
    hubId?: string | number
}


const defaultState : State = {
    hubOpen: false,
}



export const hubsReducer = (state: State = defaultState, action:HubDispatchTypes):State => {
    switch(action.type){
        case GET_SINGLE_HUB_REQUEST:
            return{
                hubOpen: false
            }
        case GET_SINGLE_HUB_SUCCESS:
            return {
                hubOpen: action.payload.hubOpen,
                hubId:action.payload.hubId,
            }

        case GET_SINGLE_HUB_FAIL:
            return {
                hubOpen: false
            }
        
        case HUB_CLOSE_SUCCESS:
            return {
                hubOpen: false
            
        }

            
            default:
                return state
    }
}


interface HubChartOpen{
    HubChartOpen: boolean
    HubChartId?: number | string
}

const hubChartDefault:HubChartOpen = {
    HubChartOpen: false
}

export const hubChartOpenReducer = (state: HubChartOpen = hubChartDefault, action:hubChartDispatchTypes):HubChartOpen => {
    switch(action.type){

        case GET_SINGLE_CHART_REQUEST:
            return{
                HubChartOpen: false
            }

        case GET_SINGLE_CHART_SUCCESS:
            return{
                HubChartOpen:action.payload.HubChartOpen,
                HubChartId:action.payload.HubChartId
            }
        
        case GET_SINGLE_CHART_FAIL:
            return{
                HubChartOpen: false
            }
        
        case HUB_CHART_CLOSE_SUCCESS:
            return{
                HubChartOpen: false
            }
        
        default:
            return state
    }
}

interface Publish{
   publish: boolean 
}

const publishState:Publish = {
    publish: false
}

export const publishScreenShowReducers = (state: Publish = publishState, action:AnyAction):Publish =>{
    switch(action.type){
        case SHOW_PUBLISH_SCREEN:
            return{
                publish: true
            }
            
        case HIDE_PUBLISH_SCREEN:
            return{
                publish: false
            }
        
        default:
            return state
    }
}