import { AnyAction } from "redux"
import {  } from "redux/constants/LeftNavOpenConst"
import {  ANALYZER_OPEN_SUCCESS3, CHART_OPEN_SUCCESS3, DATA_OPEN_SUCCESS3, DATA_SET_OPEN_SUCCESS3, SHARING_OPEN_SUCCESS3 } from "redux/constants/TopNavOpenConst"


interface State {
    dataSetR:boolean
    chartR:boolean
    dataR:boolean
    analyzerR:boolean
    sharingR:boolean
}

const defaultState : State ={
    dataSetR: false,
    chartR: true,
    dataR:false,
    analyzerR:false,
    sharingR:false
}

export const TopNavOpenerReducerRight = (state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case DATA_SET_OPEN_SUCCESS3 :
            return {
                dataSetR: true,
                chartR: false,
                dataR:false,
                analyzerR:false,
                sharingR:false
            }
        case CHART_OPEN_SUCCESS3 :
            return {
                dataSetR: false,
                chartR: true,
                dataR:false,
                analyzerR:false,
                sharingR:false
            }

        case DATA_OPEN_SUCCESS3 :
            return {
                dataSetR: false,
                chartR: false,
                dataR:true,
                analyzerR:false,
                sharingR:false
            }
        case ANALYZER_OPEN_SUCCESS3 :
            return {
                dataSetR: false,
                chartR: false,
                dataR:false,
                analyzerR:true,
                sharingR:false
            }

        case SHARING_OPEN_SUCCESS3 :
            return {
                dataSetR: false,
                chartR: false,
                dataR:false,
                analyzerR:false,
                sharingR:true
            }

            default:
                return state
    }
}