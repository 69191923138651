import { AnyAction } from "redux"
import {  } from "redux/constants/LeftNavOpenConst"
import {  ANALYZER_OPEN_SUCCESS2, CHART_CLOSE_SUCCESS, CHART_OPEN_SUCCESS2, DATA_CLOSE_SUCCESS, DATA_OPEN_SUCCESS2, DATA_SET_CLOSE_SUCCESS, DATA_SET_OPEN_SUCCESS2, SHARING_CLOSE_SUCCESS, SHARING_OPEN_SUCCESS2 } from "redux/constants/TopNavOpenConst"


interface State {
    dataSetL:boolean
    chartL:boolean
    dataL:boolean
    analyzerL:boolean
    sharingL:boolean
}

const defaultState : State ={
    dataSetL: false,
    chartL: true,
    dataL:false,
    analyzerL:false,
    sharingL:false
}

export const TopNavOpenerReducerLeft = (state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case DATA_SET_OPEN_SUCCESS2 :
            return {
                dataSetL: true,
                chartL: false,
                dataL:false,
                analyzerL:false,
                sharingL:false
            }
        case CHART_OPEN_SUCCESS2 :
            return {
                dataSetL: false,
                chartL: true,
                dataL:false,
                analyzerL:false,
                sharingL:false
            }

        case DATA_OPEN_SUCCESS2 :
            return {
                dataSetL: false,
                chartL: false,
                dataL:true,
                analyzerL:false,
                sharingL:false
            }
        case ANALYZER_OPEN_SUCCESS2 :
            return {
                dataSetL: false,
                chartL: false,
                dataL:false,
                analyzerL:true,
                sharingL:false
            }

        case SHARING_OPEN_SUCCESS2 :
            return {
                dataSetL: false,
                chartL: false,
                dataL:false,
                analyzerL:false,
                sharingL:true
            }

            default:
                return state
    }
}