export const TUTORIALS_OPEN_SUCCESS = "TUTORIALS_OPEN_SUCCESS"
export const TUTORIALS_CLOSE_SUCCESS = "TUTORIALS_CLOSE_SUCCESS"

export const INBOX_OPEN_SUCCESS = "INBOX_OPEN_SUCCESS"
export const INBOX_CLOSE_SUCCESS = "INBOX_CLOSE_SUCCESS"

export const HUBS_OPEN_SUCCESS = "HUBS_OPEN_SUCCESS"
export const HUBS_CLOSE_SUCCESS = "HUBS_CLOSE_SUCCESS"

export const NOTES_OPEN_SUCCESS = "NOTES_OPEN_SUCCESS"
export const NOTES_CLOSE_SUCCESS = "NOTES_CLOSE_SUCCESS"

export const SETTINGS_OPEN_SUCCESS = "SETTINGS_OPEN_SUCCESS"
export const SETTINGS_CLOSE_SUCCESS = "SETTINGS_CLOSE_SUCCESS"