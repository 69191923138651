/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { AboutPage } from './pages/AboutPage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
// import { useTranslation } from 'react-i18next';
import { BlogPages } from './pages/BlogPages/Loadable';
import { SingleBlog } from './pages/SingleBlog/Loadable';
import { VideosPage } from './pages/VideosPage/Loadable';
import { TermsAndConditions } from './pages/TermsAndCondition/Loadable';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { SoftWarePage } from './pages/SoftWarePage/Loadable';
import Rearrange from './pages/Rearrange';
import CoNodes from './pages/CoNodes';
import CoNodeTest2 from './pages/CoNodes/index2';

export function App() {
  // const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Connect-us now"
        defaultTitle="Connect-us now"
      // htmlAttributes={{ lang: i18n.language }}
      >

        <meta name="description" content="Create your chart" />
      </Helmet>

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/latest" component={BlogPages} />
        <Route path="/singleblog" component={SingleBlog} />
        <Route path="/videos" component={VideosPage} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/software" component={SoftWarePage} />
        <Route path='/rearrange' component={Rearrange} />
        <Route path='/conodes' component={CoNodeTest2} />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
