import { Action, AnyAction } from "redux"
import { DESELECT_SUBCHART_TYPE, SELECT_CHART_TYPE_FAIL, SELECT_CHART_TYPE_REQ, SELECT_CHART_TYPE_SUCC, SELECT_SUBCHART_TYPE_FAIL, SELECT_SUBCHART_TYPE_REQ, SELECT_SUBCHART_TYPE_SUCC } from "redux/constants/Chart/SelectChartType"


interface State {
    chartId?: string | number,
    loading:boolean
}

const defaultState : State = {
    loading:false,
    chartId:1
}


export const chartTypeSecelctionReducer = (state: State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case SELECT_CHART_TYPE_REQ:
            return{
                loading:true,
            }
        case SELECT_CHART_TYPE_SUCC:
            return {
                chartId:action.payload.chartId,
                loading:false
            }

        case SELECT_CHART_TYPE_FAIL:
            return {
                loading:false
            }

            default:
                return state
    }
}


interface StateSubChart {
    subChartId?: string | number |undefined,
    loading:boolean
}

const defaultStateSunChart : StateSubChart = {
    loading:false,
    subChartId:undefined
}



export const subChartTypeSecelctionReducer = (state: StateSubChart = defaultStateSunChart, action:AnyAction):StateSubChart => {
    switch(action.type){
        case SELECT_SUBCHART_TYPE_REQ:
            return{
                loading:true,
            }
        case SELECT_SUBCHART_TYPE_SUCC:
            return {
                subChartId:action.payload.subChartId,
                loading:false
            }

        case SELECT_SUBCHART_TYPE_FAIL:
            return {
                loading:false
            }

        case DESELECT_SUBCHART_TYPE:
            return{
                subChartId:undefined,
                loading:false
            }

            default:
                return state
    }
}

