import { Action, AnyAction } from "redux"
import { GET_SINGLE_NODE_TYPE_FAIL, GET_SINGLE_NODE_TYPE_REQUEST, GET_SINGLE_NODE_TYPE_SUCC } from "redux/constants/SpecialOptionsChart"



interface State {
    nodeId?: string | number,
    loading:boolean
}

const defaultState : State = {
    loading:false,
    nodeId:0
}

export const nodeTypeSecelctionReducer = (state: State = defaultState, action:any):State => {
    switch(action.type){
        case GET_SINGLE_NODE_TYPE_REQUEST:
            return{
                loading:true,
            }
        case GET_SINGLE_NODE_TYPE_SUCC:
            return {
                nodeId:action.payload.nodeId,
                loading:false
            }

        case GET_SINGLE_NODE_TYPE_FAIL:
            return {
                loading:false
            }

            default:
                return state
    }
}